import { Container, Title, Text, Loader } from '@mantine/core';
import { Component, useEffect, useState } from "react";
import axios from "axios";
import * as Luxon from 'luxon';
import * as R from 'ramda';

import * as constants from './../../constants';
import { apiEventTypeToEventType, Event, StringEventTypeToSpecialEventType, EventType, ApiEventType, SpecialEventType } from './event'

interface State {
  error: Error;
  isLoaded: Boolean;
  items: EventType[]
}

export default function Events() {
  const [data, setData] = useState([] as EventType[]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const startOfToday = Luxon.DateTime.now().minus({ days: 1 }).toUnixInteger();
        const response = await axios.get(`${constants.backend}/events?_limit=25&_sort=date&date_gte=${startOfToday}`);
        const events = R.pipe(
          R.filter((event: ApiEventType) => event.date >= startOfToday),
          R.map((event: ApiEventType) => apiEventTypeToEventType(event))
        )(response.data);
        setData(events);
        setError(null);
      } catch (err: any) {
        setError(err.message);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return (
      <>
        <Title order={2}>Kommende Events</Title>
        <Loader variant="dots" />
      </>
    )
  } else {
    return (
      <>
        <Title order={2}>Kommende Events</Title>
        {
          data.map((eventDate) => {
            return (
              <Event id={eventDate.id} image={eventDate.image} structure={eventDate.structure} description={eventDate.description} type={eventDate.type} location={eventDate.location} date={eventDate.date} />
            )
          })
        }
      </>
    );
  }
}
