import { Container, Avatar, Tooltip, Title, TextInput, Checkbox, Button, Group, Box, Text, TypographyStylesProvider, Spoiler } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RichTextEditor } from '@mantine/rte';
import * as Luxon from 'luxon';

import Header from './../header';

export function NewsEntry(props: NewsEntryType) {
  return (
    <>
      <Spoiler maxHeight={300} hideLabel="Weniger anzeigen" showLabel="Mehr anzeigen" transitionDuration={2000}>
        <TypographyStylesProvider>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </TypographyStylesProvider>
        <Text color="dimmed" size="sm">von {props.author} am {props.date.toLocaleString()}</Text>
      </Spoiler>
    </>
  );
}

export interface NewsEntryType {
  id: number;
  content: string;
  author: string;
  date: Luxon.DateTime;
}

export interface ApiNewsType {
  id: number;
  content: string;
  author: string
  date: number;
}

export function apiNewsTypeToNewsType(newsEntry: ApiNewsType) {
  const newObject: NewsEntryType = {
    id: newsEntry.id,
    content: newsEntry.content,
    author: newsEntry.author,
    date: Luxon.DateTime.fromSeconds(newsEntry.date),
  };

  return newObject;
}