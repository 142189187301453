import { Container, Title, TextInput, Checkbox, Button, Group, Box, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { RichTextEditor } from '@mantine/rte';

import Header from './../header';

export default function NewsCreate() {  
  return (
    <>
      <Header/>
      <Title order={1}>Neuer Newsartikel</Title>
      <RichTextEditor value={""} onChange={console.log} id="rte" />
      <Button>Speichern</Button>
    </>
  );
}