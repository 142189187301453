import { Container, Text, createStyles, Card, Image, Group, RingProgress, rem, ActionIcon, TypographyStylesProvider, Button, Title, Badge } from '@mantine/core';
import { Link, useNavigate } from "react-router-dom";
import { IconBrandGoogleMaps } from '@tabler/icons-react';
import * as Luxon from 'luxon';
import { google_maps_url } from '../../constants';
import React from 'react';
import { FeatureFlags } from '../../contexts/feature_flags';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    borderBottom: 'rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))',
    padding: '0 1rem',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },

  label: {
    textTransform: 'uppercase',
    fontSize: 'var(--mantine-font-size-xs)',
    fontWeight: 700,
  }
}));

export function Workshop(props: WorkshopType) {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const features = props.requirements.length
    ? props.requirements.map((requirement) => (
      <Badge variant="light" key={requirement}>
        {requirement}
      </Badge>
    ))
    : (<Badge variant="light" key="none">
      Keine
    </Badge>);

  return (
    <Card withBorder radius="md" p="md" className={classes.card} style={{ margin: "20px 0" }}>
      <Card.Section>
        <Image src={props.image} alt={props.title} height={180} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group>
          <Text fz="lg" fw={500}>
            {props.title}
          </Text>
          <Badge size="sm" variant="light">
            {props.location}
          </Badge>
        </Group>
        <Text fz="sm" mt="xs">
          {props.description}
        </Text>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text mt="md" c="dimmed" className={classes.label}>
          Voraussetzung
        </Text>
        <Group mt={5}>
          {features}
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button radius="md" style={{ flex: 1 }} onClick={() => {
          navigate(`${props.id}/signup`, { state: props });
        }}>
          Details / Anmeldung
        </Button>
      </Group>
    </Card>
  );
}

export interface ApiWorkshopType {
  id: number;
  title: string;
  description: string;
  content: string;
  location: string;
  image: string;
  date: number;
  requirements: string[];
}

export interface LocationType {
  alias: string;
  lat: number;
  lon: number;
}

export interface WorkshopType {
  id: number;
  title: string;
  description: string;
  content: string;
  location: string;
  image: string;
  date: Luxon.DateTime;
  requirements: string[];
}

export function apiWorkshopTypeToWorkshopType(rawWorkshop: ApiWorkshopType) {
  const newObject: WorkshopType = {
    id: rawWorkshop.id,
    title: rawWorkshop.title,
    description: rawWorkshop.description,
    content: rawWorkshop.content,
    location: rawWorkshop.location,
    image: rawWorkshop.image,
    date: Luxon.DateTime.fromSeconds(rawWorkshop.date),
    requirements: rawWorkshop.requirements,
  };

  return newObject;
}