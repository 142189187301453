import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

import App from "./app";
import { EnvironmentVariables } from "./env";
import reportWebVitals from "./reportWebVitals";
import German from './lang/de.json';
import English from './lang/en.json';
import { FeatureFlags, FeatureFlagsProvider } from './contexts/feature_flags'

const locale = navigator.language;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare global {
  interface Window { env: EnvironmentVariables; }
}

window.env = window.env || {};

root.render(
  <IntlProvider locale={locale} messages={English}>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <FeatureFlagsProvider>
        <Notifications />
        <App />
      </FeatureFlagsProvider>
    </MantineProvider>
  </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
