import { createStyles, Container, Group, ActionIcon, Text, rem, Anchor } from '@mantine/core';
import { IconBrandFacebook, IconBrandInstagram, IconBrandVimeo } from '@tabler/icons-react';

import { getAppVersion, app_version_date } from './../constants';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

const links = [{
  label: 'Impressum',
  href: 'about#impressum'
}];

export default function Footer() {
  const { classes } = useStyles();

  const items = links.map((link) => (
    <Anchor<'a'>
      color="black"
      key={link.href}
      href={link.href}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Group>
          <Text color="dimmed" size="sm">
            Geupdated am {app_version_date} ({getAppVersion()})
          </Text>
        </Group>
        <Group className={classes.links}>{items}</Group>
        <Group spacing="xs" position="right" noWrap>
          <a target='_blank' rel="noreferrer" href="https://www.facebook.com/profile.php?id=100064456551128">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandFacebook size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </a>
          <a target='_blank' rel="noreferrer" href="https://www.instagram.com/swing_jugend/">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandInstagram size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </a>
          <a target='_blank' rel="noreferrer" href="https://vimeo.com/swingjugend">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandVimeo size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </a>
        </Group>
      </Container>
    </div>
  );
}
