import { Title, Text } from '@mantine/core';

import * as constants from '../constants';


export default function Newsletter() {
  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
        <Title order={2}>Anmeldung zum Newsletter</Title>
        <p>
          <Text>Unser Newsletter informiert euch monatlich über alle möglichen Lindy Hop Ereignisse in Wuppertal, wann welche Parties, Socials und eben Trainings stattfinden. Oder auch seltener mal Workshops. Mit der Eingabe eurer E-Mail stimmt ihr zu, dass wir eure Daten (die E-Mail) verarbeiten dürfen.</Text>
          <Text>Ihr <b>abonniert</b> den Newsletter <a href={constants.newsletter_signup} target="_blank">hier</a>. Natürlich könnt ihr euch immer wieder ab- und wieder anmelden und auch eure Daten löschen.</Text>
          <Text><b>Vergangene</b> Newsletter könnt ihr unter dieser <a href="https://newsletter.swingjugend.com/archive" target="_blank">Webseite</a> finde und nachlesen.</Text>
        </p>
      </div>
    </>
  );
}
