import { Grid, Container, Image, Text, Box, SimpleGrid, Stack } from "@mantine/core";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Newsletter from './pages/newsletter';
import HeaderSimple from './pages/header';
import Footer from './pages/footer';
import NotFound from './pages/error_pages/not_found';
import { News } from './pages/news/news';
import EventDetail from './pages/events/event_details'
import NewsCreate from './pages/news/create';
import Events from './pages/events/events';
import Workshops from './pages/workshops/workshops';
import About from './pages/about';
import Confirm from './pages/confirm';
import WorkshopAnmeldung from './pages/workshops/workshop_anmeldung';
import * as Luxon from 'luxon';
import { theme } from "./theme";
import { FeatureFlags } from "./contexts/feature_flags";
import React from "react";
import Friends from "./pages/friends";
import Training from "./pages/training";

Luxon.Settings.defaultLocale = 'de';

const Home = () => (
  <>
    <Grid style={{ margin: '0 8px' }}>
      <Grid.Col xs={"auto"}><News /></Grid.Col>
      <Grid.Col xs={4}>
        <Events />
      </Grid.Col>
    </Grid>
  </>
);

export default function App() {
  const { workshopsEnabled, friends } = React.useContext(FeatureFlags);
  return (
    <Router>
      <HeaderSimple />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/training' element={<Training />} />
        <Route path='/confirm/:id' element={<Confirm />} />
        <Route path='/events/:id' element={<EventDetail />} />
        <Route path='/newsletter' element={<Newsletter />} />
        <Route path='/news/create' element={<NewsCreate />} />
        {workshopsEnabled && (
          <Route path='/workshops' element={<Workshops />} />
        )}
        {workshopsEnabled && (
          <Route path='/workshops/:id/signup' element={<WorkshopAnmeldung />} />
        )}
        {friends.enabled && (
          <Route path='/friends' element={<Friends />} />
        )}
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}
