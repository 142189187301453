import { Container, Title, Text, Loader } from '@mantine/core';
import { Component, useEffect, useState } from "react";
import axios from "axios";
import * as Luxon from 'luxon';

import Header from './../header';
import Footer from './../footer';
import * as constants from '../../constants';
import { apiWorkshopTypeToWorkshopType, WorkshopType, Workshop, ApiWorkshopType } from './workshop'

interface State {
  error: Error;
  isLoaded: Boolean;
  items: WorkshopType[]
}

const mockData = [{
  id: 1,
  title: 'Shim Sham mit Tobi',
  description: 'Die beliebteste Lindy Hop Choreographie ist zurück in der Swingjugend und wir zeigen euch wie auch ihr das nächste mal den Shim Sham mittanzen könnt!',
  location: 'Die Boerse',
  date: Luxon.DateTime.now().toUnixInteger(),
  image: 'https://images.unsplash.com/photo-1437719417032-8595fd9e9dc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80',
  requirements: [
  ]
}, {
  id: 2,
  title: 'Aerials mit Nils und Bianca',
  description: 'In diesem Workshop zeigen euch Nils und Bianca die Basics in Aerials, wie man am einfachsten Leute durch die Gegend wirbelt und wie man nicht auf die Nase fällt.',
  location: 'Die Boerse',
  date: Luxon.DateTime.now().toUnixInteger(),
  image: 'https://images.unsplash.com/photo-1437719417032-8595fd9e9dc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80',
  requirements: [
    'Bereits drei Jahre Tanzerfahrung'
  ]
}]

function transformWorkshops(rawWorkshops: any) {
  const workshops = rawWorkshops as ApiWorkshopType[];
  return workshops.map(workshop => apiWorkshopTypeToWorkshopType(workshop));
}

export default function Workshops() {
  const [data, setData] = useState([] as WorkshopType[]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        // const response = await axios.get(`${constants.backend}/workshops?limit=10&_sort=date`);
        // const workshops = response.data.filter((workshop: any) => workshop.date > Luxon.DateTime.now().toUnixInteger())
        console.log('success');
        setData(transformWorkshops(mockData));
        setError(null);
      } catch (err: any) {
        console.log('failed');
        setError(err.message);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return (
      <>
        <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
          <Title order={2}>Aktuelle Workshops</Title>
          <Loader variant="dots" />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
          <Title order={2}>Aktuelle Workshops</Title>
          {
            data.map((workshop) => {
              return (
                <Workshop
                  key={workshop.id}
                  location={workshop.location}
                  date={workshop.date}
                  id={workshop.id}
                  title={workshop.title}
                  description={workshop.description}
                  content={workshop.content}
                  image={workshop.image}
                  requirements={workshop.requirements} />
              )
            })
          }
        </div>
      </>
    );
  }
}
