import { Container, Title, AspectRatio } from '@mantine/core';
import {
  useParams
} from "react-router-dom";

import Header from './../header';
import Footer from './../footer';

export default function EventDetail() {
  const { id } = useParams();
  return (
    <>
      <Header/>
      <Title order={1}>Event Detail, yay with parameter: {id}</Title>
      <div style={{width: '30%'}}>
        <AspectRatio ratio={16 / 9}>
          <iframe frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2496.501644664038!2d7.144538251428998!3d51.26508453611198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8d7b005822f41%3A0xb630148934dadf91!2zQ2Fmw6kgQWRh!5e0!3m2!1sen!2sde!4v1665953417206!5m2!1sen!2sde" width="600" height="450" loading="lazy"></iframe>
        </AspectRatio>
      </div>
      <Footer/>
    </>
  );
}