import { Title, TextInput, Checkbox, Button, Group, Box, Text, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { Component, useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import * as Luxon from 'luxon';

import * as constants from '../../constants';
import { WorkshopType } from './workshop';


export default function WorkshopAnmeldung() {
  const { state } = useLocation();

  console.log('title:', state.title);
  const form = useForm({
    initialValues: {
      email: '',
      role: '',
      name: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ungueltige E-Mail'),
      name: (value) => value.length > 0 ? null : 'Bitte Namen eingeben',
      termsOfService: (termsOfService) => termsOfService ? null : 'Bitte Verarbeitung zustimmen',
    },
  });

  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
        <Title order={2}>Anmeldung zum Workshop "{state.title}"</Title>
        <Text>Hier könnt ihr euch fuer den Workshop anmelden. Mit der Eingabe eurer E-Mail stimmt ihr zu, dass wir eure Daten (die E-Mail) verarbeiten duerfen. Nach einer erfolgreichen Anmeldung bekommt ihr eine Bestätigung per E-Mail.</Text>
        <Box sx={{ maxWidth: 350 }}>
          <form onSubmit={form.onSubmit((values) => {
            console.log('values:');
            console.log(values);
            return axios.post(`${constants.backend}/mail`, values)
              .then(() => {
                notifications.show({
                  title: 'Anmeldung erfolgreich',
                  message: 'Deine Anmeldung beim Workshop war erfolgreich. Bitte bestätige noch die Anmeldung in Deinem Postfach!',
                  color: 'green'
                });
              })
              .catch((err) => {
                notifications.show({
                  title: 'Anmeldung fehlgeschlagen',
                  message: 'Deine Anmeldung beim Workshop war nicht erfolgreich.',
                  color: 'red',
                  autoClose: 5000,
                });
              });
          })}>
            <TextInput
              withAsterisk
              label="Email"
              placeholder="deine-email@irgendwas.de"
              {...form.getInputProps('email')}
            />

            <TextInput
              withAsterisk
              label="Name"
              placeholder="Max Mustermann"
              {...form.getInputProps('name')}
            />

            <Select
              withAsterisk
              mt="md"
              withinPortal
              data={['Solo', 'Lead', 'Follow', 'Egal']}
              placeholder="Pick one"
              label="Rolle"
              {...form.getInputProps('role')}
            />

            <Checkbox
              mt="md"
              label="Ich stimme der Verarbeitung meiner E-Mail zu"
              {...form.getInputProps('termsOfService', { type: 'checkbox' })}
            />

            <Group position="left" mt="md">
              <Button type="submit">Zum Workshop anmelden</Button>
            </Group>
          </form>
        </Box>
      </div>
    </>
  );
}
