// export const backend = 'https://api.swing.shazf.xyz';
// export const backend = 'http://localhost:7777';
export const backend = 'https://api.swingjugend.com';
export const app_version = 'APP_VERSION';
export const app_version_date = 'APP_VERSION_DATE';
export const google_maps_url = 'https://www.google.de/maps/';
export const newsletter_signup = 'https://newsletter.swingjugend.com/subscription/form';

export const getAppVersion = () => {
    return window.env.APP_VERSION || 'APP_VERSION';
}

export const getAppVersionDate = () => {
    return window.env.APP_VERSION_DATE || 'APP_VERSION_DATE';
}