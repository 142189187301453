import { Container, Text, createStyles, Card, Image, Group, RingProgress, rem, ActionIcon, Badge } from '@mantine/core';
import { Link } from "react-router-dom";
import { IconBrandGoogleMaps } from '@tabler/icons-react';
import * as Luxon from 'luxon';
import { google_maps_url } from '../../constants';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

const trainingsDefaultText = 'Im ersten Trainingsblock bekommen besonders Neulinge und Interessierte Tänzer Eindrücke im Lindy Hop vermittelt. Nach der ersten Stunde gibts es eine halbe Stunde lang Socialtanz und im Anschluss eine weitere Stunde für Tänzer, die noch nicht genug gefordert waren.';
const partyDefaultText = 'DJ Tobi legt wieder im Ada auf. Kommt vorbei und lasst die Schuhe qualmen!';

function descriptionText(props: EventType) {
  if (props.description) {
    return props.description;
  }

  if (props.type === SpecialEventType.Party) {
    return partyDefaultText;
  }

  if (props.type === SpecialEventType.Training) {
    return trainingsDefaultText;
  }
}

export function Event(props: EventType) {
  const { classes } = useStyles();

  const usedPicture = props.image
    ? props.image
    : SpecialEventTypeToPicture.get(props.type);
  const items = props.structure.map((innerEvent) => {
    const timeAsString = innerEvent.time.toUnixInteger() === 0
      ? "Open End"
      : innerEvent.time.toFormat('HH:mm')
    return (
      <div key={innerEvent.title}>
        <Text size="xs" color="dimmed">
          {innerEvent.title}
        </Text>
        <Text weight={500} size="sm">
          {timeAsString}
        </Text>
      </div>
    );
  });

  return (
    <Card withBorder padding="lg" className={classes.card} style={{ margin: "20px 0" }}>
      <Card.Section>
        <Image src={usedPicture} alt="frankie and friends" height={100} />
      </Card.Section>

      <Group position="apart" mt="xl">
        <Text fz="sm" fw={700} className={classes.title}>
          {SpecialEventTypeToTitle.get(props.type)} am {props.date.toLocaleString()}
        </Text>
        <Group spacing={5}>
          <Badge size="sm" variant="light">
            {props.location.alias}
          </Badge>
          <a target='_blank' rel="noreferrer" href={google_maps_url + 'place/' + props.location.lat + ',' + props.location.lon}>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandGoogleMaps size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </a>
        </Group>
      </Group>
      <Text mt="sm" mb="md" c="dimmed" fz="xs">
        {descriptionText(props)}
      </Text>
      <Card.Section className={classes.footer}>{items}</Card.Section>
    </Card>
  );
}

export interface ApiEventType {
  id: number;
  description?: string | undefined;
  type: string;
  location: LocationType;
  date: number;
  image?: string | undefined;
  structure: StructureApiType[]
}

export interface LocationType {
  alias: string;
  lat: number;
  lon: number;
}

export interface StructureApiType {
  title: string;
  time: number;
}

export interface StructureType {
  title: string;
  time: Luxon.DateTime;
}

export interface EventType {
  id: number;
  description?: string | undefined;
  image?: string | undefined;
  type: SpecialEventType;
  location: LocationType;
  date: Luxon.DateTime;
  structure: StructureType[];
}

export function apiEventTypeToEventType(event: ApiEventType) {
  console.log(event);
  const specialType = StringEventTypeToSpecialEventType.get(event.type);
  if (specialType === void 0) {
    throw new Error(`mismatch in the types: ${event.type}`);
  }
  const newObject: EventType = {
    id: event.id,
    description: event.description,
    image: event.image,
    date: Luxon.DateTime.fromSeconds(event.date),
    location: event.location,
    type: specialType,
    structure: event.structure.map((item) => {
      return {
        title: item.title,
        time: Luxon.DateTime.fromSeconds(item.time),
      };
    })
  };

  return newObject;
}

export enum SpecialEventType {
  Party,
  Training,
  Workshop,
  Social
}

export const StringEventTypeToSpecialEventType = new Map<string, SpecialEventType>([
  ['party', SpecialEventType.Party],
  ['workshop', SpecialEventType.Workshop],
  ['training', SpecialEventType.Training],
  ['social', SpecialEventType.Social],
]);

export const SpecialEventTypeToTitle = new Map<SpecialEventType, string>([
  [SpecialEventType.Party, "Party"],
  [SpecialEventType.Workshop, "Workshop"],
  [SpecialEventType.Training, "Training"],
  [SpecialEventType.Social, "Social"]
]);

export const SpecialEventTypeToPicture = new Map<SpecialEventType, string>([
  [SpecialEventType.Party, "/slide1.jpg"],
  [SpecialEventType.Workshop, "/frankie-and-friends.jpg"],
  [SpecialEventType.Training, "/frankie-and-friends.jpg"],
  [SpecialEventType.Social, "/Elisenturm_Wuppertal.jpg"]
]);
