import { Container, Title, Text, TypographyStylesProvider, Loader } from '@mantine/core';
import { Component, useEffect, useState } from "react";

import axios from "axios"
import * as Luxon from 'luxon';
import * as R from 'ramda';

import * as constants from './../../constants';
import { NewsEntryType, NewsEntry, apiNewsTypeToNewsType, ApiNewsType } from './news_entry';

export function News() {
  const [data, setData] = useState([] as NewsEntryType[]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get<ApiNewsType[]>(`${constants.backend}/news?_sort=-date`);
        const news = R.pipe(
          R.sortWith<ApiNewsType>([
            R.descend(R.prop('date'))
          ]),
          R.map((newsEntry: ApiNewsType) => apiNewsTypeToNewsType(newsEntry)),
        )(response.data);
        setData(news);
        setError(null);
      } catch (err: any) {
        setError(err.message);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return (
      <>
        <Title order={2}>Ankündigungen</Title>
        <Loader variant="dots" />
      </>
    )
  } else {
    return (
      <>
        <Title order={2}>Ankündigungen</Title>
        {
          data.map((newsEntry) => {
            return (
              <NewsEntry date={newsEntry.date} id={newsEntry.id} author={newsEntry.author} content={newsEntry.content} />
            )
          })
        }
      </>
    );
  }
}

