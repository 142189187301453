import { useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import axios from "axios";

import * as constants from './../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

interface ApiConfirmationType {
  id: string,
  type: string,
  successMessage: string,
};

export default function Confirm() {

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const confirm = async () => {
      try {
        const response = await axios.get<ApiConfirmationType>(`${constants.backend}/confirmations/${id}`);
        notifications.show({
          title: `${response.data.type} Bestätigung`,
          message: `${response.data.successMessage} Du wirst in wenigen Augenblicken weitergeleitet.`,
          color: 'green',
          autoClose: 3000,
          loading: true,
        });
      } catch (err) {
        notifications.show({
          title: 'Bestätigung nicht gefunden',
          message: 'Deine Bestätigung konnte nicht gefunden werden - vielleicht ist sie abgelaufen? Du wirst weitergeleitet.',
          color: 'red',
          autoClose: 3000,
          loading: true,
        });
      } finally {
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    };

    confirm();
  }, []);

  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
        <Title order={2}>Bestätigung</Title>
      </div>
    </>
  );
}