import { useEffect, useState } from 'react';
import { createStyles, Header, Container, Group, Burger, rem, Image, Transition, Paper, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import React from 'react';
import { FeatureFlags } from '../contexts/feature_flags';

const HEADER_HEIGHT = 300;

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    cursor: 'pointer',
    userSelect: 'none',
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

interface HeaderSimpleProps {
  links: { link: string; label: string }[];
}

const workshopsLink = {
  label: 'Workshops',
  link: 'workshops'
};

const friendsLink = {
  label: 'Friends',
  link: 'friends'
};

const headerLinks: HeaderSimpleProps = {
  links: [
    {
      label: 'Home',
      link: ''
    }, {
      label: 'Swingjugend',
      link: 'about'
    }, {
      label: 'Unterricht',
      link: 'training'
    }, {
      label: 'Newsletter',
      link: 'newsletter'
    }
  ]
}

function isPathKnownLink(currentPath: string) {
  const pathWithoutLeadingSlash = currentPath.replace('/', '');
  const isKnownLink = headerLinks.links.find((link) => {
    return link.link === pathWithoutLeadingSlash;
  });

  console.log(`Looks like ${currentPath} is known: ${!!isKnownLink}`);

  return isKnownLink;
}

function addWorkshopLinkIfNeeded(workshopsEnabled: Boolean) {
  if (!workshopsEnabled) {
    return;
  }

  if (headerLinks.links.includes(workshopsLink)) {
    return;
  }

  headerLinks.links.push(workshopsLink);
}

function addFriendsLinkIfNeeded(friendsEnabled: Boolean) {
  if (!friendsEnabled) {
    return;
  }

  if (headerLinks.links.includes(friendsLink)) {
    return;
  }

  headerLinks.links.push(friendsLink);
}

function setActiveLink(location: Location, setActive: React.Dispatch<React.SetStateAction<string>>) {
  if (isPathKnownLink(location.pathname)) {
    const activeLink = location.pathname.replace('/', '');
    console.log(`setting active to: ${activeLink}`);
    setActive(activeLink);
  } else {
    console.log(`The link ${location.pathname} is not known`);
  }
}

export default function HeaderSimple() {
  const { workshopsEnabled, friends } = React.useContext(FeatureFlags);
  const navigate = useNavigate();
  const location = useLocation();
  const links = headerLinks.links;
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState('');
  const { classes, cx } = useStyles();

  addWorkshopLinkIfNeeded(workshopsEnabled);
  addFriendsLinkIfNeeded(friends.enabled);
  useEffect(() => {
    setActiveLink(location, setActive);
  }, [location]);

  function navigateToOtherLink(targetLink: string) {
    navigate(targetLink);
    setActive(targetLink);
  }

  const items = links.map((link) => (
    <a
      key={link.label}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={() => {
        navigateToOtherLink(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={HEADER_HEIGHT} mb={120}>
      <Image src="/slide2.jpg" alt="slide 3" height={300} />
      <Container className={classes.header}>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}
