import React, { Context } from 'react';
import axios from 'axios';
import Ajv from 'ajv';

import * as constants from '../constants';

const ajv = new Ajv({ useDefaults: true });

const featureFlagSchema = {
  type: 'object',
  properties: {
    workshopsEnabled: {
      type: 'boolean',
      default: false
    },
    friends: {
      type: 'object',
      properties: {
        enabled: {
          type: 'boolean',
          default: false
        }
      },
      default: {
        enabled: false
      }
    },
    additionalProperties: false,
  }
};

const validate = ajv.compile(featureFlagSchema);

interface Features {
  workshopsEnabled: Boolean
  friends: {
    enabled: Boolean
  }
};

const defaultFeatureFlags: Features = {
  workshopsEnabled: false,
  friends: {
    enabled: false
  }
}

const defaultFeatures: Features = { workshopsEnabled: false, friends: { enabled: false } };
export const FeatureFlags: Context<Features> = React.createContext(defaultFeatures);
export const FeatureFlagsProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [features, setFeatures] = React.useState(defaultFeatures);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${constants.backend}/feature-flags`);
        const valid = validate(response.data);

        if (!valid) {
          console.error(`Passed data is invalid! Errors: ${validate.errors}`);
          console.error('Setting default FeatureFlags!');
          setFeatures(defaultFeatureFlags);
        } else {
          console.log('Received data is correct!');
          setFeatures(response.data);
        }
      } catch (err) {
        console.log('no feature flags - use default');
        setFeatures(defaultFeatureFlags);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <FeatureFlags.Provider value={features}>
      {isLoading ? "Loading..." : children}
    </FeatureFlags.Provider>
  );
};