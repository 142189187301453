import { Title, Text } from '@mantine/core';

import * as constants from '../constants';


export default function Friends() {
  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: '60rem' }}>
        <Title order={2}>Unsere Partnerseiten</Title>
        <Text>Auf dieser Seite findet ihr lauter Querverweise zu anderen Seiten, Partnern und alte Freunde der Swingjugend. Schaut gerne bei ihnen vorbei!</Text>
      </div>
    </>
  );
}
