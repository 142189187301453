import { Title, Text, List } from '@mantine/core';

export default function Training() {
  return (
    <>
      <div style={{ margin: '0 auto', maxWidth: '60rem' }}>
        <Title order={2}>Unterricht</Title>
        <Text>
          <p>
            Der Unterricht bei der SwingJugend findet jeden Mittwoch in der Boerse Wuppertal statt.
            Dabei fangen wir um 19:00 mit der ersten Stunde, die sich mit den Anfängern und neu-begeisterten des Lindy Hops widmet, an.
            Um 20:00 wird die Tanzfläche für einen Sozialtanz geöffnet. Hier kann wirklich jeder, der irgendwie Lust hat, vorbei kommen und mittanzen!
            Um 20:30 beginnt die zweite Stunde, die sich eher an erfahrenere Tänzer widmet.
          </p>
        </Text>
        <Title order={3}>Erste Stunde (Beginner/Anfänger)</Title>
        <Text>
          <p>
            <List>
              <List.Item>An Beginner/Anfänger des Lindy Hops gerichtet</List.Item>
              <List.Item>Dennoch bitte einen Einsteigerworkshop vorher machen</List.Item>
              <List.Item>Beginn: 19:00, jeden Mittwoch</List.Item>
              <List.Item>Ende: 20:00</List.Item>
              <List.Item>Ort: die Boerse Wuppertal</List.Item>
            </List>
          </p>
        </Text>
        <Title order={3}>Sozialtanz</Title>
        <Text>
          <p>
            <List>
              <List.Item>Jeder darf mittanzen und Spass haben</List.Item>
              <List.Item>Beginn: 20:00, jeden Mittwoch</List.Item>
              <List.Item>Ende: 20:30</List.Item>
              <List.Item>Ort: die Boerse Wuppertal</List.Item>
            </List>
          </p>
        </Text>
        <Title order={3}>Zweite Stunde (Intermediate/Erfahrener)</Title>
        <Text>
          <p>
            <List>
              <List.Item>An erfahrenere Tänzer des Lindy Hops gerichtet</List.Item>
              <List.Item>Wenn ihr unsicher seid, ob ihr "erfahren genug" seid, fragt einfach unsere Lehrer</List.Item>
              <List.Item>Beginn: 20:30, jeden Mittwoch</List.Item>
              <List.Item>Ende: 21:30</List.Item>
              <List.Item>Ort: die Boerse Wuppertal</List.Item>
            </List>
          </p>
        </Text>
        <Title order={3}>Kosten & Preise</Title>
        <Text>
          <p>
            Da die SwingJugend den Unterricht auf ehrenamtliche Basis betreibt, sind die Kosten pro Teilnahme an einem Tag bei <b>3,75 EUR</b>.
            Diese werden in Form einer Stempelkarte, abkassiert und kann beim Unterricht in <b>Bargeld</b> gekauft werden.
            Mit einem Stempel dürft ihr in beiden Stunden teilnehmen!
          </p>
        </Text>
        <Title order={3}>Fragen und Anregungen</Title>
        <Text>
          <p>
            Habt ihr noch mehr Fragen oder generelle Fragen? Dann schreibt uns gerne bei Facebook, Instagram oder per <a href="mailto:info@swingjugend.com">E-Mail</a>. Wir freuen uns auf euch!
          </p>
        </Text>
      </div>
    </>
  );
}