import { Title, Text } from '@mantine/core';

export default function About() {
  return (
    <>
      <div style={{ margin: '0 auto', maxWidth: '60rem' }}>
        <Title order={2}>Die Swingjugend</Title>
        <p>
          <Text>
            Die Swingjugend ist seit über zehn Jahren in Wuppertal für leidenschaftliches Lindy Hop tanzen bekannt. Aus einer interessierten Gruppen haben sich immer mehr Leute zusammen gefunen um den Tanz im Tal zu leben.
          </Text>
        </p>
        <Title order={3}>Haftungsausschluss</Title>
        <Text>
          <p>
            Diese Website enthält als solche gekennzeichnete Links oder Verweise auf Websites Dritter. Die Verantwortlichkeit für diese fremden Inhalte liegt hierbei alleine bei dem Anbieter, welcher die Inhalte bereithält. Die Verantwortlichen dieser Seite vermitteln lediglich den Zugang zur Nutzung dieser Inhalte. Bei der erstmaligen Verknüpfung mit einem anderen Internetangebot ist dessen Inhalt daraufhin geprüft worden, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Auf die aktülle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten besteht jedoch keinerlei Einfluss. Wir distanzieren uns daher ausdrücklich von allen Inhalten verlinkter Seiten, welche nach der Setzung der Verlinkung verändert wurden.
          </p>
          <p>
            Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung von Informationen Dritter entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
          </p>
          <p>
            Die Verantwortlichen dieser Seite übernehmen weder Gewähr für die Aktualität, Richtigkeit, Vollständigkeit, Qualität und jederzeitige Verfügbarkeit der bereitgestellten Informationen noch für das Ausbleiben anderweitiger technischer Störungen. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern der Nachweis vorsätzlichen oder grob fahrlässigen Verhaltens seitens der Betreiber nicht erbracht wurde.
          </p>
        </Text>
        <Title order={3}>Datenschutzerklärung</Title>
        <Title order={4}>Allgemeiner Hinweis und Pflichtinformationen</Title>
        <Title order={5}>Benennung der verantwortlichen Stelle</Title>
        <Text>
          <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
          <code>
            SwingJugend<br />
            Stöber<br />
            Pfaffenberger Weg 214<br />
            42659 Solingen
          </code>
          <p>
            Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
          </p>
        </Text>
        <Title order={5}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Title>
        <Text>
          <p>
            Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
        </Text>
        <Title order={5}>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</Title>
        <Text>
          <p>
            Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
          </p>
        </Text>
        <Title order={5}>Recht auf Datenübertragbarkeit</Title>
        <Text>
          <p>
            Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </p>
        </Text>
        <Title order={5}>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</Title>
        <Text>
          <p>
            Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
          </p>
        </Text>
        <Title order={5}>SSL- bzw. TLS-Verschlüsselung</Title>
        <Text>
          <p>
            Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
          </p>
        </Text>
        <Title order={5}>Newsletter-Daten</Title>
        <Text>
          <p>
            Zum Versenden unseres Newsletters benötigen wir von Ihnen eine E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse ist notwendig und der Empfang des Newsletters ist einzuwilligen. Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt ausschließlich für den Versand des Newsletters.
            Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail oder Sie melden sich über den "Austragen"-Link im Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.

            Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.
          </p>
        </Text>
      </div>
    </>
  );
}